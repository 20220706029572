require('./bootstrap')

import Editor from '@toast-ui/editor'
// // import 'codemirror/lib/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css'

if (document.querySelector('#editor')) {
    const editor = new Editor({
        el: document.querySelector('#editor'),
        height: '400px',
        initialEditType: 'wysiwyg',
        placeholder: ''
    })
    // if (document.querySelector('#createAnnouncementForm')) {
    //     document.querySelector('#createAnnouncementForm').addEventListener('submit', e => {
    //         e.preventDefault()
    //         document.querySelector('#content').value = editor.getMarkdown()
    //         e.target.submit()
    //     })
    // }
    //
    // if (document.querySelector('#editAnnouncementForm')) {
    //     editor.setMarkdown(document.querySelector('#oldContent').value)
    //
    //     document.querySelector('#editAnnouncementForm').addEventListener('submit', e => {
    //         e.preventDefault()
    //         document.querySelector('#content').value = editor.getMarkdown()
    //         e.target.submit()
    //     })
    // }
}
